

const Interface = () => {
    return (
        <>
            <div style={styles.containerDiv}>

            </div>
        </>
    );
}

const styles = {
    containerDiv: {
        width: '800px',
        height: '800px',
    }
}

export default Interface