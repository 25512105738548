import Signup from "../../components/Login/Signup";

const SignUpPage = () => {
    return (
        <>
            <Signup />
        </>
    );
}

export default SignUpPage