import PokerTableImage from '../../app/assets/play-room/poker-table.png'
import { generateHoleCards, generateRiver } from '../../slices/Engine/engineSlice';
import { pokerEngine } from '../../poker-engine/classes/engine';
import River from './River';
import UserProfileHud from './UserProfileHud';
import axios from 'axios';
import { finalPlayers, formatPlayer, setPlayers } from '../../slices/Player/playerSlice';
import { sepNumByComma } from '../../utils/formatNumbers';
import { useSelector } from 'react-redux';


/* 

simple solutions: 
    -put any class related function calls inside of an array. 
    access the index of the array from the useState value holder.

complex solutions: 
    -use Array prototypes to hold all of the values and methods of the 
    player, playerHUD, and playerUtil classes. This way the useState
    update functions will accept changes.

*/


const Table = (props) => {
    const { width, height } = props;
    const riverArr = generateRiver()
    //console.log(riverArr)
    const holeCardsArr = pokerEngine.generateHoleCards(3)
    // const playerString = axios.get("/")

    const formattedPlayers = useSelector(state => state.playroom.config.playerNames) || [];


    console.log(formattedPlayers)

    const players = finalPlayers(formattedPlayers)

    return (
        <>
            <div className='center-content' style={{width: width, height: height, backgroundImage: `url(${PokerTableImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative'}}>
                <River riverArr={riverArr}/>
                {/* {setPlayers(formatPlayer(playerString))} */}
                <UserProfileHud players={players} />
            </div>
        </>
    );
}


export default Table