export const PRODUCTS = () => {
    /* change the structure of the return object
    so that it works dynamically with a db table */
    return [
        {
            name: '30,000',
            price: '1.49',
            vip: '300',
            popular: false,
            discount: 0, /* percentage: 0, 0.15, 0.65, etc */
            special_offer: false,
            value: 30000,
            prod_id: 'pro_01havg1qngg1ykdbd2e0951nt2',
        },
        {
            name: '70,000',
            price: '2.99',
            vip: '400',
            popular: false,
            discount: 0,
            special_offer: false,
            value: 70000,
            prod_id: 'pro_01havgatfr04mys204exsmy0f8',
        },
        {
            name: '145,000',
            price: '4.99',
            vip: '500',
            popular: false,
            discount: 0,
            special_offer: false,
            value: 145000,
            prod_id: 'pro_01havvbdedx4ehj5d4kw296s5m',
        },
        {
            name: '250,000',
            price: '7.99',
            vip: '600',
            popular: false,
            discount: 0,
            special_offer: false,
            value: 250000,
            prod_id: 'pro_01havvj2d7hdex64z573zphx0c',
        },
        {
            name: '300,000',
            price: '9.99',
            vip: '700',
            popular: false,
            discount: 0,
            special_offer: false,
            value: 300000,
            prod_id: 'pro_01havvs08fabacsxae5jx5pwq4',
        },
        {
            name: '500,000',
            price: '14.99',
            vip: '800',
            popular: false,
            discount: 0,
            special_offer: false,
            value: 500000,
            prod_id: 'pro_01havvxhj1twypk3nep9qhqbhh',
        },
        {
            name: '1,150,000',
            price: '19.99',
            vip: '900',
            popular: false,
            discount: 0,
            special_offer: false,
            value: 1150000,
            prod_id: 'pro_01havw2ev8bjfxaskc91qm5dyr',
        },
        {
            name: '3,000,000',
            price: '49.99',
            vip: '3,000',
            popular: false,
            discount: 0,
            special_offer: false,
            value: 3000000,
            prod_id: 'pro_01havw4w1kjeqr38e3sbdqxf80',
        },
    ]
}