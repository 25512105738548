import ProductList from "../components/TopUp/ProductList.js";

const TopUpPage = () => {
    return (
        <>
            <ProductList />
        </>
    );
}

export default TopUpPage